import { render, staticRenderFns } from "./default.vue?vue&type=template&id=4c61665e&scoped=true"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"
import style0 from "./default.vue?vue&type=style&index=0&id=4c61665e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c61665e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TheHeader: require('/codebuild/output/src4096606143/src/holaluz-conversion-conversionsolar-solarsalesagentui/src/ui/components/TheHeader/index.js').default})
